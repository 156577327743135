export type ApiType =
  | 'config'
  | 'configWebView'
  | 'setWebViewTitle'
  | 'goBack'
  | 'backExec'
  | 'fetchToken'
  | 'close'
  | 'login'

// declare global {
//   interface Window {
//     h5Token?: string

//     NativeBridge?: {
//       invoke: (name: ApiType, params: any, callback?: (a: any) => void) => void
//       on: (name: ApiType, callback?: (a: any) => void) => void
//       fetchToken: (name: ApiType, callback?: (a: any) => void) => void
//       backExec: (name: ApiType, callback?: (a: any) => void) => void
//     }
//   }
// }
/**
 * APP端h5 JS API
 */
class AppApi {
  // 是否是dreo app嵌入
  dreo = false
  // 是否要根据app账号来判断
  appAccount = false
  isClient = false

  bridgeState = {
    state: 0,
  }

  bridgeReadyHandlers = {
    _completes: [] as Array<() => void>,
    _fail: 0,
  }

  configuration = {
    debug: process.env.NODE_ENV !== 'production',
    // debug: true,
  }

  supportApis: Record<ApiType, ApiType> = {
    config: 'config',
    configWebView: 'configWebView',
    setWebViewTitle: 'setWebViewTitle',
    goBack: 'goBack',
    backExec: 'backExec',
    fetchToken: 'fetchToken',
    close: 'close',
    login: 'login',
  }

  constructor() {
    this.initJsWebView()
  }

  initJsWebView() {
    // 客户端
    if (typeof window !== 'undefined') {
      this.isClient = true

      if (navigator.userAgent) {
        this.dreo = !!navigator.userAgent
          .toLocaleLowerCase()
          ?.includes('hesung')
        this.appAccount = !!navigator.userAgent
          .toLocaleLowerCase()
          ?.includes('app_account')
      }
    }
  }

  getNativeBridge() {
    return window?.NativeBridge
  }

  invoke(name: ApiType, params?: any, obj?: any) {
    if (window?.NativeBridge) {
      this.log(name, params)
      window.NativeBridge.invoke(name, params, (a) => {
        this.response(name, a, obj)
      })
    } else {
      this.log(name, obj)
    }
  }
  on(name: ApiType, obj: any) {
    if (window?.NativeBridge) {
      window.NativeBridge.on(name, (a: any) => {
        this.response(name, a, obj)
      })
    } else {
      this.log(name, obj)
    }
  }

  callAfterBridgeReady(func: () => void) {
    if (this.dreo) {
      window?.NativeBridge
        ? func()
        : document?.addEventListener &&
          document?.addEventListener('NativeBridgeReady', func, false)
    } else {
      console.log('App not dreo')
    }
  }
  response(name: ApiType, result: any, obj: any) {
    const resp = obj ? obj : {}
    if (resp._complete) {
      resp._complete(result)
    }
    if (this.configuration.debug) {
      alert(JSON.stringify(result))
    }
    const code = result.code ? result.code : '2'
    switch (code) {
      case '0':
        if (resp.success) {
          resp.success(result.data)
        }
        break
      case '-1':
        if (resp.cancel) {
          resp.cancel(result.data)
        }
        break
      case '1':
        if (resp.trigger) {
          resp.trigger(result.data)
        } else {
          if (resp.complete) {
            resp.complete(result.data)
          }
        }
        break
      default:
        if (resp.failure) {
          resp.failure(result.data)
        }
    }
  }

  log(name: ApiType, obj: any) {
    if (this.configuration.debug) {
      let apiName = name
      const supportApi = this.supportApis[apiName]
      if (supportApi) {
        apiName = supportApi
      }
      if (obj && obj._complete) {
        delete obj._complete
      }
      console.log('"' + apiName + '",', obj || '')
    }
  }

  fetchToken(params: any, callback: (obj: any) => void) {
    if (!window?.NativeBridge) return this.log('fetchToken', params)
    window?.NativeBridge &&
      window.NativeBridge.fetchToken(params, function (token) {
        callback && callback(token)
      })
  }
  config(cfg: any) {
    this.configuration = cfg
    this.log('config', cfg)
    this.callAfterBridgeReady(() => {
      this.bridgeState.state = 1
      const a = this.bridgeReadyHandlers._completes
      for (let i = 0; i < a.length; i++) {
        a[i]()
      }
      this.bridgeReadyHandlers._completes = []
    })
  }
  ready(a: any) {
    if (this.configuration.debug || this.bridgeState.state == 1) {
      a()
    } else {
      this.bridgeReadyHandlers._completes.push(a)
    }
  }
  error(a: any) {
    if (this.bridgeState.state === -1) {
      a()
    } else {
      this.bridgeReadyHandlers._fail = a
    }
  }
  configWebView(a: any) {
    const params = {} as any
    if (a.trackId != undefined) {
      params['trackId'] = a.trackId
    }
    params['inlinePlay'] = a.inlinePlay == undefined ? false : a.inlinePlay
    params['autoPlay'] = a.autoPlay == undefined ? false : a.autoPlay
    params['bounces'] = a.bounces == undefined ? true : a.bounces
    params['title'] = a.title
    params['hideBackKey'] = a.hideBackKey == undefined ? false : a.hideBackKey
    this.invoke('configWebView', params, a)
  }
  setWebViewTitle(a: any) {
    const params = {} as any
    if (a.trackId != undefined) {
      params['trackId'] = a.trackId
    }
    params['title'] = a.title
    this.invoke('configWebView', params, a)
  }
  goBack(a: any) {
    const params = {} as any
    if (a.trackId != undefined) {
      params['trackId'] = a.trackId
    }
    params['update'] = a.update
    this.invoke('goBack', params, a)
  }
  // backExec(a: any) {
  //   this.on("backExec", a);
  // }
  backExec(params: any, callback: (obj: any) => void) {
    this.invoke('goBack', params, callback)
  }
  close(params: any, callback: (obj: any) => void) {
    this.invoke('close', params, callback)
  }
  login(params?: any, callback?: (obj: any) => void) {
    this.invoke('login', params, callback)
  }
}

export default new AppApi()
