import { m, LazyMotion, domAnimation } from 'framer-motion'
import { Box, Typography } from '@mui/material'
import Link from 'next/link'

const Logo = () => {
  return (
    <Link href="/" passHref legacyBehavior>
      <Box
        component="a"
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& svg': {
            fontSize: {
              xl: '62px',
              lg: '44px',
              xs: '52.72px',
            },
            userSelect: 'none',
            width: '1em',
            height: 'auto',
            flexShrink: 0,
            verticalAlign: 'middle',
          },
        }}
      >
        <Typography sx={{ textIndent: '-999em' }} component="span">
          Dreo
        </Typography>
        <LazyMotion features={domAnimation}>
          <m.svg width="16" height="16" viewBox="0 0 2998 1024">
            <m.path
              fill="#000"
              d="M2484.593326 0.092119a507.961555 507.961555 0 0 0-337.712266 128.852668V0.092119H1529.627877v939.83976l-230.979495-323.26894a299.954909 299.954909 0 0 0 189.18483-285.170401A340.157396 340.157396 0 0 0 1139.942381 0.092119h-334.016139v334.982819c-62.549839-211.98709-244.513006-335.49459-508.132146-334.982819L0 0.717617V1023.634936h297.794096c264.187774 0 445.866624-123.109456 508.53019-334.18673V1023.634936h205.845834V539.840365L1337.827326 1023.634936h809.053734v-127.999715a511.771409 511.771409 0 0 0 850.677808-381.895198A514.955764 514.955764 0 0 0 2484.593326 0.092119zM299.101957 818.585192H205.049744V203.890867h92.118854c202.547751 0 325.71407 116.797608 325.71407 308.598159 0 190.492691-122.483957 306.096166-323.780711 306.096166z m844.650278-356.988989L1011.715212 460.686387V203.890867h132.093887a131.638979 131.638979 0 0 1 132.037023 128.852668 130.786027 130.786027 0 0 1-132.037023 128.966395z m591.380295 168.202203h182.588666V392.734516h-182.361212V202.63987h343.057434a511.430228 511.430228 0 0 0-105.25432 311.043289 505.743879 505.743879 0 0 0 102.638599 305.243214h-340.441713z m749.233342 193.335865a311.782515 311.782515 0 1 1 312.749194-310.872699 311.782515 311.782515 0 0 1-312.52174 311.100153z"
            />
            <m.path
              fill="#000"
              d="M46.324 25.3941C46.045 24.079 45.8537 22.7454 45.7514 21.4029C45.5394 19.1977 45.3326 17.8195 44.6911 16.6122C43.88 15.0686 40.8582 14.0267 40.8582 14.0267C41.7148 13.8356 42.5423 13.524 43.318 13.1006C44.052 12.7272 44.6994 12.1929 45.2156 11.5343C45.7318 10.8757 46.1045 10.1085 46.3081 9.28569C46.6686 7.53262 46.8382 5.13454 44.4154 2.90185C41.9291 0.597489 37.6031 0.564412 37.6031 0.564412H25.6855V27.7426H33.24V14.6993C33.6323 14.6993 34.3003 14.6993 34.7827 14.6993C35.3051 14.6984 35.8206 14.8234 36.2888 15.0644C36.757 15.3055 37.1651 15.656 37.4812 16.0885C38.1863 17.1911 38.2075 19.3025 38.2075 22.8142C38.2075 26.3258 39.0504 27.7757 39.0504 27.7757L47.257 27.6654C46.7817 26.9933 46.4623 26.2159 46.324 25.3941ZM37.5077 11.6783C36.6382 12.4997 34.1254 12.4666 33.2665 12.428V3.05621C34.1511 3.01886 35.0371 3.05019 35.9172 3.14992C36.8079 3.32633 38.6528 3.86108 38.9974 6.71672C39.3208 9.39044 38.568 10.6639 37.5077 11.6783Z"
            />
            <m.path
              fill="#000"
              d="M57.9501 24.909V14.2307H65.7962V11.4633H57.9501V3.33185H67.2807V0.564412H50.3479L50.2948 24.909V27.6765H68.8499V24.909H57.9501Z"
            />
            <m.path
              fill="#000"
              d="M74.1516 2.42818C74.7452 1.94855 75.3933 1.54677 76.0814 1.2319C78.0641 0.338822 80.6618 -0.17387 84.1395 0.25613C86.2418 0.478976 88.2176 1.40566 89.7697 2.89677C90.814 4.00225 91.6343 5.3145 92.1818 6.75574C93.0513 8.96087 94.0532 14.38 92.59 20.0747C91.1268 25.7695 87.2144 27.1862 85.4702 27.6659C83.726 28.1455 78.1542 28.7684 74.7507 26.2325C71.3472 23.6966 70.5466 20.9016 70.1596 16.1441C70.1066 15.5101 70.1172 14.7879 70.0907 14.176C69.7886 7.81972 72.365 4.34113 72.365 4.34113C72.8809 3.62798 73.4812 2.98527 74.1516 2.42818ZM81.6426 2.25177C78.0429 2.25177 78.0482 7.54959 78.0482 14.0823C78.0482 20.615 77.7301 25.7695 81.6426 25.9128C85.2422 26.0451 85.2369 20.615 85.2369 14.0823C85.2369 7.54959 85.2581 2.25177 81.6426 2.25177Z"
            />
          </m.svg>
        </LazyMotion>
      </Box>
    </Link>
  )
}

export default Logo
