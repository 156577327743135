import { isDreoApp } from '@/utils'
import appApi from '@/utils/appApi'
import { appFeedback } from '@/utils/urlConfig'
import { router as hsRouter } from '@hsfe/dreojsapi'
import {
  AppBar,
  Box,
  BoxProps,
  Fab,
  Toolbar,
  useScrollTrigger,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { domAnimation, LazyMotion, m } from 'framer-motion'
import dynamic from 'next/dynamic'
// import {
//   ContactSupport,
//   SupportPolicy2,
//   SupportProduct,
//   SupportRequest2,
//   SupportWarranty,
// } from '@icons'
import Link from 'next/link'
// import MenuToggle from './MenuToggle'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useWindowScroll } from 'react-use'
import AppContainer from './AppContainer'
// import NavMenus from './NavMenus'
// import NavToolbar from './NavToolbar'
import Logo from './Logo'
// import NavMenus from './SiteNavMenus/NavMenus'

// 2.0版本未启用
const AnnouncementBar = dynamic(() => import('./AnnouncementBar'))
const MobileNavDrawer = dynamic(() => import('./MobileNavDrawer'))
const ScrollTop = dynamic(() => import('./ScrollTop'))
const MenuToggle = dynamic(() => import('./MenuToggle'))
const MobileMenus = dynamic(() => import('./SiteNavMenus/MobileMenus'))
const NavMenus = dynamic(() => import('./SiteNavMenus/NavMenus'))
const NavToolbar = dynamic(() => import('./NavToolbar'))
const ContactSupport = dynamic(
  () => import('src/icons/components/ContactSupport')
)
const SupportPolicy2 = dynamic(
  () => import('src/icons/components/SupportPolicy2')
)
const SupportProduct = dynamic(
  () => import('src/icons/components/SupportProduct')
)
const SupportRequest2 = dynamic(
  () => import('src/icons/components/SupportRequest2')
)
const SupportWarranty = dynamic(
  () => import('src/icons/components/SupportWarranty')
)

const navMenus = [
  {
    isProductNav: true,
    title: 'Products',
    url: '/products',
  },
  // {
  //   title: 'Christmas Sale',
  //   url: '/christmas-sale',
  // },
  {
    title: 'Support',
    url: '/support',
    children: [
      {
        url: '/support/product',
        title: 'Product Support',
        subTitle: 'FAQ & User Manual Download',
        icon: <SupportProduct sx={{ color: '#696969' }} />,
      },
      {
        url: '/warranty',
        title: 'Warranty Activation',
        subTitle: 'Register Your Warranty',
        icon: <SupportWarranty sx={{ color: '#696969' }} />,
      },
      {
        url: '/support/request',
        title: 'Request Support',
        subTitle: 'Submit Your Support Request',
        icon: <SupportRequest2 sx={{ color: '#696969' }} />,
      },
      {
        url: '/support-policy/warranty-policy',
        title: 'Support Policy',
        subTitle: 'Shipping, Return & Refund Policy',
        icon: <SupportPolicy2 sx={{ color: '#696969' }} />,
      },
      // {
      //   url: '/recipes',
      //   title: 'Recipes',
      // },
    ],
  },
  {
    title: 'About Us',
    url: '/about-us',
  },
  {
    title: 'Blog',
    url: '/blogs/all',
  },
]
export const headerHeight = {
  xs: 48,
  md: 48,
  lg: 42,
  xl: 66,
}

declare global {
  interface Window {
    // app传过来的token
    h5Token?: string
  }
}

type HeaderWindow = Window & { h5Token?: string }

const Header = ({
  headerHidden,
  feedbackHidden,
  ...props
}: BoxProps & {
  window?: () => HeaderWindow
  /** 是否透明 */
  transparent?: boolean
  // 是否隐藏 支持/滚动到顶部 按钮
  toolButtonHidden?: boolean

  /** 隐藏feedback */
  feedbackHidden?: boolean

  /** 是否关闭粘性定位 */
  disableSticky?: boolean

  /** 是否隐藏头部 */
  headerHidden?: boolean
}) => {
  const {
    sx = {},
    window,
    transparent,
    toolButtonHidden,
    disableSticky,
  } = props
  const router = useRouter()

  // app client是否隐藏头部
  const [showHeader, setShowHeader] = useState(true)

  // const [announcementBarHeight] = useState<number>(0)
  const [announcementBarHeight, setAnnouncementBarHeight] = useState<number>(0)

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })
  const theme = useTheme()
  const lgMatches = useMediaQuery(theme.breakpoints.up('lg'))

  const scrollTopVisible = useMemo(() => trigger, [trigger])

  // 是否显示菜单弹窗
  const [opened, setOpened] = useState<boolean>(false)

  const handleToggle = useCallback(
    () => setOpened((prevState) => !prevState),
    [setOpened]
  )
  const onClose = useCallback(() => setOpened(false), [setOpened])

  // 发生路由跳转则关闭弹窗
  const handleRouterChange = () => setOpened(false)

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouterChange)
    return () => {
      router.events.off('routeChangeStart', handleRouterChange)
    }
  }, [router.events])

  const { y } = useWindowScroll()

  const headerVariants = useMemo(
    () => ({
      // 首页鼠标移入效果
      hover: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        // borderColor: opened ? 'rgba(0,0,0,0.25)' : 'transparent'
        borderColor: 'rgb(228, 228, 228)',
      },

      // 滚动置顶
      scroll: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'rgb(228, 228, 228)',
      },

      // 移动端展开菜单效果
      opened: {
        backgroundColor: '#FFFFFF',
        transition: { duration: 0 },
        borderColor: 'rgb(228, 228, 228)',
      },

      // 默认状态
      default: {
        backgroundColor: transparent
          ? 'rgba(255,255,255,0)'
          : 'rgba(255, 255, 255, 1)',
        borderColor: !transparent ? 'rgb(228, 228, 228)' : 'rgba(0,0,0,0)',
      },
    }),
    [transparent]
  )

  const appBarAnimate = useMemo(
    () => (opened ? 'opened' : y > 0 ? 'scroll' : 'default'),
    [opened, y]
  )

  const headerStyles = useMemo(
    () => ({
      bgcolor: transparent ? 'enviLight_neutral.main' : 'rgba(0,0,0,0)',
      height: transparent ? 0 : 'auto',
    }),
    [transparent]
  )

  const onAnnouncementBarShow = useCallback((height: number) => {
    setAnnouncementBarHeight(height)
  }, [])

  useEffect(() => {
    if (opened && lgMatches) {
      setOpened(false)
    }
  }, [lgMatches, opened, setOpened])

  useEffect(() => {
    setShowHeader(!appApi.appAccount)
  }, [])

  // if (!showHeader) return <></>

  return (
    <LazyMotion features={domAnimation}>
      <Box
        component="header"
        // className={'dreo-app-hide'}
        sx={{
          position: opened ? 'fixed' : disableSticky ? 'relative' : 'sticky',
          left: 0,
          top: 0,
          right: 0,
          zIndex: 1160,
          bgcolor: headerStyles.bgcolor,
          height: headerStyles.height,
          display: headerHidden || !showHeader ? 'none' : 'block',
          ...sx,
        }}
      >
        <AnnouncementBar onShow={onAnnouncementBarShow} />

        <AppBar
          position="static"
          component={m.div}
          initial="default"
          animate={appBarAnimate}
          whileHover="hover"
          variants={headerVariants}
          sx={{
            boxShadow: 'none',
            borderBottom: '1px solid rgb(228, 228, 228)',
            height: {
              xs: `${headerHeight.xs}px!important`,
              md: `${headerHeight.md}px!important`,
              lg: `${headerHeight.lg}px!important`,
              xl: `${headerHeight.xl}px!important`,
            },
            minHeight: {
              xs: `${headerHeight.xs}px!important`,
              md: `${headerHeight.md}px!important`,
              lg: `${headerHeight.lg}px!important`,
              xl: `${headerHeight.xl}px!important`,
            },
          }}
        >
          <Toolbar
            sx={{
              px: 0,
              minHeight: {
                xs: `${headerHeight.xs}px!important`,
                md: `${headerHeight.md}px!important`,
                lg: `${headerHeight.lg}px!important`,
                xl: `${headerHeight.xl}px!important`,
              },
            }}
          >
            <AppContainer maxWidth="xl" sx={{ height: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'space-between',
                  '&>div': {
                    height: '100%',
                  },
                  position: {
                    xs: 'relative',
                    lg: 'static',
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flex: 1,

                    '&>div': {
                      height: '100%',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: { xs: 'flex', lg: 'none' },
                      alignItems: 'center',
                      flexBasis: '90px',
                      height: '100%',
                      position: 'absolute',
                      left: 0,
                    }}
                  >
                    <MenuToggle toggle={handleToggle} />
                  </Box>

                  <Box
                    className="logo-wrapper"
                    sx={{
                      display: 'flex',
                      justifyContent: {
                        xs: 'center',
                        lg: 'flex-start',
                      },
                      flex: { xs: 1, lg: 'unset' },
                      width: {
                        xs: 'auto',
                        lg: 173,
                        xl: 238,
                      },
                    }}
                  >
                    <Logo />
                  </Box>

                  <Box display={{ xs: 'none', lg: 'block' }}>
                    <NavMenus menuList={navMenus} />
                  </Box>
                </Box>

                <Box
                  sx={{
                    height: '100%',
                    flexBasis: { xs: '90px', lg: 'auto' },
                    position: {
                      xs: 'absolute',
                      lg: 'static',
                    },
                    right: { xs: 0, lg: 'auto' },
                  }}
                >
                  <NavToolbar />
                </Box>
              </Box>
            </AppContainer>
          </Toolbar>
        </AppBar>

        <MobileNavDrawer
          opened={opened}
          onClose={onClose}
          headerHeight={headerHeight}
          announcementBarHeight={announcementBarHeight}
        >
          <MobileMenus menuList={navMenus} />
        </MobileNavDrawer>
      </Box>
      {!toolButtonHidden && (
        <>
          {feedbackHidden && (
            <Box
              sx={{
                position: 'fixed',
                bottom: 154,
                right: 16,
                zIndex: 200,
              }}
            >
              <Link href="/support/request" passHref legacyBehavior>
                <Fab
                  size="small"
                  aria-label="support"
                  component="a"
                  onClick={(
                    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                  ) => {
                    // app 内跳转到 app 反馈页
                    if (isDreoApp()) {
                      e.preventDefault()
                      hsRouter.push({ url: appFeedback })
                    }
                  }}
                  sx={{
                    bgcolor: 'enviLight_background.main',
                    width: 48,
                    height: 48,
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
                    '&:hover': {
                      bgcolor: 'rgb(237, 237,237)',
                    },
                  }}
                >
                  <ContactSupport sx={{ color: 'enviLight_onSurface.main' }} />
                </Fab>
              </Link>
            </Box>
          )}
          <ScrollTop {...props} visible={scrollTopVisible}></ScrollTop>
        </>
      )}
    </LazyMotion>
  )
}

Header.whyDidYouRender = true

// ScrollTop.whyDidYouRender = true

export default Header
