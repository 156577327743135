// import Footer from './Footer'
// import AnnouncementBar from './AnnouncementBar'
import { Box, Toolbar } from '@mui/material'
import { SxProps } from '@mui/system'
import dynamic from 'next/dynamic'
// const AppMain = dynamic(() => import('./AppMain'))
// const Header = dynamic(() => import('./Header'))
import React, { ReactNode, useEffect } from 'react'
// const Header = dynamic(() => import('./Header'))
// const Footer = dynamic(() => import('./Footer'))
import { GoogleTagManager } from 'src/utils/gtm'
import AppMain from './AppMain'
import Header from './Header'
// import dynamic from 'next/dynamic'
// const AnnouncementBar = dynamic(() => import('./AnnouncementBar'))
// import WithReduxContext from 'src/modules/components/WithReduxContext'
// import WithGlobalMessageBoxContext from 'src/modules/components/WithGlobalMessageBoxContext'
// import WithAuthContext from 'src/modules/components/WithAuthContext'
// import WithSwrContext from 'src/modules/components/WithSwrContext'
// import Message from './Message'

const Footer = dynamic(() => import('./Footer'))
const Message = dynamic(() => import('./Message'))
const CookiesDialog = dynamic(() => import('./CookiesDialog'), { ssr: false })
const WithGlobalMessageBoxContext = dynamic(
  () => import('src/modules/components/WithGlobalMessageBoxContext')
)
const WithReduxContext = dynamic(
  () => import('src/modules/components/WithReduxContext')
)
const WithSwrContext = dynamic(
  () => import('src/modules/components/WithSwrContext')
)
const WithAuthContext = dynamic(
  () => import('src/modules/components/WithAuthContext')
)
const WithCartContext = dynamic(
  () => import('src/modules/components/WithCartContext')
)

export interface LayoutProps {
  children: ReactNode
  /**
   * 是否透明
   */
  transparent?: boolean

  appMainSx?: SxProps
  footerSx?: SxProps
  contactUs?: React.ReactNode
  // 隐藏页脚
  footerHidden?: boolean
  feedbackHidden?: boolean
  toolButtonHidden?: boolean

  cookiesDialogHidden?: boolean

  disableSticky?: boolean

  // 隐藏头部
  headerHidden?: boolean
}

export const withLayout =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  (props: P) =>
    (
      <Layout>
        <Component {...props} />
      </Layout>
    )

const Layout = ({
  children,
  transparent,
  appMainSx,
  footerSx,
  contactUs,
  footerHidden,
  feedbackHidden,
  toolButtonHidden,
  disableSticky,
  headerHidden,
  cookiesDialogHidden,
}: LayoutProps) => {
  // 滚动事件监听
  useEffect(() => {
    const onScroll = () => {
      let scrollTop = 0
      let bodyScrollTop = 0
      let documentScrollTop = 0
      let scrollHeight = 0,
        bodyScrollHeight = 0,
        documentScrollHeight = 0
      let windowHeight = 0
      if (document.body) {
        bodyScrollTop = document.body.scrollTop
        bodyScrollHeight = document.body.scrollHeight
      }
      if (document.documentElement) {
        documentScrollTop = document.documentElement.scrollTop
        documentScrollHeight = document.documentElement.scrollHeight
      }

      scrollTop =
        bodyScrollTop - documentScrollTop > 0
          ? bodyScrollTop
          : documentScrollTop

      scrollHeight =
        bodyScrollHeight - documentScrollHeight > 0
          ? bodyScrollHeight
          : documentScrollHeight
      if (document.compatMode == 'CSS1Compat') {
        windowHeight = document.documentElement.clientHeight
      } else {
        windowHeight = document.body.clientHeight
      }

      if (scrollTop + windowHeight > scrollHeight - 90) {
        // 埋点上报
        GoogleTagManager.customEvent('scroll_to_bottom', {
          page_title: document.querySelector('title')?.innerText,
        })
        window.removeEventListener('scroll', onScroll)
      }
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])
  return (
    <WithReduxContext>
      <WithGlobalMessageBoxContext>
        <WithSwrContext>
          <WithAuthContext>
            <WithCartContext>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateRows: 'auto auto minmax(0, 1fr) auto',
                  gridTemplateColumns: '100%',
                  gridTemplateAreas: `
                    "toolbar"
                    "header"
                    "main"
                    "footer"
                  `,
                  minHeight: '100vh',
                }}
              >
                <Box sx={{ gridArea: 'toolbar' }}>
                  <Toolbar
                    id="back-to-top-anchor"
                    sx={{
                      minHeight: '0px!important',
                      height: '0px!important',
                      overflow: 'hidden',
                    }}
                  />
                </Box>
                {/* <AnnouncementBar sx={{ gridArea: 'announcementBar' }} /> */}
                <Header
                  sx={{ gridArea: 'header' }}
                  transparent={transparent}
                  toolButtonHidden={toolButtonHidden}
                  feedbackHidden={feedbackHidden}
                  disableSticky={disableSticky}
                  headerHidden={headerHidden}
                />
                <AppMain
                  sx={{
                    gridArea: 'main',
                    // overflow hidden 的子元素 sticky 不会生效
                    // overflow: 'hidden',
                    ...appMainSx,
                  }}
                >
                  {children}
                </AppMain>
                {!footerHidden && (
                  <Footer
                    sx={{ gridArea: 'footer', ...footerSx }}
                    contactUs={contactUs}
                  ></Footer>
                )}
              </Box>

              <Message />

              {!cookiesDialogHidden && <CookiesDialog fixed />}
            </WithCartContext>
          </WithAuthContext>
        </WithSwrContext>
      </WithGlobalMessageBoxContext>
    </WithReduxContext>
  )
}

export default Layout
