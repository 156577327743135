import { Box, BoxProps } from '@mui/material'

/**
 * 全局容器
 * @param {boxProps} BoxProps
 * @returns
 */
export default function AppMain({ children, sx = {} }: BoxProps) {
  return (
    <Box
      component="main"
      id="main"
      sx={{
        height: '100%',
        position: 'relative',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}
