import { styled } from '@mui/system'
import Container from '@mui/material/Container'

export default styled(Container)(({ theme }) => ({
  padding: '0 20px',
  [theme.breakpoints.up('md')]: {
    padding: '0 40px',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '0 118px',
  },
  [theme.breakpoints.up('xl')]: {
    padding: '0 118px',
  },
}))
